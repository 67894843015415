

































































































import { defineComponent, ref, computed } from '@vue/composition-api'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import ChipBoolean from '@/components/ChipBoolean.vue'
import {
  BaseButton,
  BaseIcon,
  BaseInputSelect
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useWorkflowGroupsMachine } from '@/machines/tableMachine'
import { useGroupTypes } from '@/composables/useGroupTypes'
import repositoryUserGroup from '@/domain/userGroupRepository'
import ListingTableMeta from '@/components/ListingTableMeta.vue'
import ListingTable from '@/components/ListingTable.vue'
import ListingPagination from '@/components/ListingPagination.vue'
import ListingFormSearch from '@/components/ListingFormSearch.vue'
import ListingTitle from '@/components/ListingTitle.vue'
import { useUser } from '@/composables/useUser'

export default defineComponent({
  components: {
    BaseCombobox,
    ChipBoolean,
    BaseButton,
    BaseIcon,
    BaseInputSelect,
    ListingTableMeta,
    ListingTable,
    ListingPagination,
    ListingFormSearch,
    ListingTitle
  },
  setup (_, { root }) {
    const { me } = useUser()
    const isGroupAdmin = computed(() => Boolean(me.value.isGroupAdmin && !me.value.isAdmin))

    const { state, send } = useWorkflowGroupsMachine()

    const workflowGroups = ref(null)

    // User Group
    const userGroupSearchPromise = q => repositoryUserGroup.findByCriteria({ q })
    const userGroupItemText = s => s.title

    // User Group Types
    const { groupTypes, findAll } = useGroupTypes()
    if (!isGroupAdmin.value) {
      findAll()
    }

    const userGroupTypeItemText = s => s.title
    const userGroupTypeItemValue = s => `/api/user_group_types/${s.id}`

    const getUserGroup = group => {
      return group.userGroup?.formattedTitle || '-'
    }

    const getUserGroupType = group => {
      return group.userGroupType?.title || '-'
    }

    const handleRowClick = ({ id }) => {
      root.$router.push({ name: 'AdminWorkflowGroupsManageDetail', params: { id } })
    }

    return {
      isGroupAdmin,
      state,
      send,
      workflowGroups,
      userGroupSearchPromise,
      userGroupItemText,
      groupTypes,
      userGroupTypeItemText,
      userGroupTypeItemValue,
      getUserGroup,
      getUserGroupType,
      handleRowClick
    }
  }
})
