






















































import { defineComponent, ref } from '@vue/composition-api'
import DialogConfirm from '@/components/DialogConfirm.vue'
import { BaseInputText } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { notEmpty, isEmail } from '@/validations'

export default defineComponent({
  props: ['form', 'state', 'send'],
  components: {
    DialogConfirm,
    BaseInputText
  },
  setup () {
    const valid = ref(true)

    return {
      notEmpty,
      isEmail,
      valid
    }
  }
})
