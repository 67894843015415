
























import { defineComponent } from '@vue/composition-api'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import DialogConfirm from '@/components/DialogConfirm.vue'
import repository from '@/domain/userRepository'
import { useUser } from '@/composables/useUser'

export default defineComponent({
  components: {
    DialogConfirm
  },
  props: ['id', 'isActive'],
  setup (props, { root }) {
    const { findForUserManagement } = useUser()
    const { state, send } = useMachine(promiseMachine, {
      context: {
        needsConfirmation: true
      },
      services: {
        invoke: (_, event: any) => {
          if (props.isActive) {
            return repository.deactivate(event.value)
          }
          return repository.activate(event.value)
        }
      },
      actions: {
        onDoneEnter: () => {
          findForUserManagement(props.id)
        }
      }
    })
    return {
      state, send
    }
  }
})
