






import { defineComponent } from '@vue/composition-api'
import FormAgendaGroupEdit from '@/components/forms/FormAgendaGroupEdit.vue'
import DetailPage from '@/components/DetailPage.vue'

export default defineComponent({
  props: ['id'],
  components: {
    FormAgendaGroupEdit,
    DetailPage
  }
})
