





























































































import { defineComponent, computed, ref } from '@vue/composition-api'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import ChipBlock from '@/components/ChipBlock.vue'
import ChipUser from '@/components/ChipUser.vue'
import FormUserDeactivate from '@/components/forms/FormUserDeactivate.vue'
import { notEmpty } from '@/validations'
import { useUser } from '@/composables/useUser'
import {
  BaseInputText,
  BaseInputSelect,
  BaseButton,
  BaseIcon
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'

export default defineComponent({
  props: ['form', 'state', 'send', 'user'],
  components: {
    BaseCombobox,
    ChipBlock,
    ChipUser,
    FormUserDeactivate,
    BaseInputText,
    BaseInputSelect,
    BaseButton,
    BaseIcon
  },
  setup (props) {
    const valid = ref(true)

    const { me } = useUser()
    const isCurrentUser = computed(() => {
      return props.user ? me.value.id === props.user.id : false
    })

    function formatRole (role) {
      return role.split('_').pop()
    }

    const roles = [
      'ROLE_ADMIN',
      'ROLE_AGENDA',
      'ROLE_JAG'
    ]

    return {
      notEmpty,
      valid,
      isCurrentUser,
      formatRole,
      roles
    }
  }
})
