

































import { defineComponent, computed } from '@vue/composition-api'
import LayoutNavContent from '@/router/layouts/LayoutNavContent.vue'
import { useUser } from '@/composables/useUser'

export default defineComponent({
  setup () {
    const { me } = useUser()

    const groups = computed(() => {
      if (me.value.isGroupAdmin && !me.value.isAdmin) {
        return [
          {
            title: 'Workflows',
            group: 'workflows',
            items: [{
              title: 'Manage Workflow Groups',
              to: { name: 'AdminWorkflowGroupsManage' }
            }, {
              title: 'Manage Templates',
              to: { name: 'AdminWorkflowTemplatesManage' }
            }]
          }
        ]
      }

      return [
        {
          title: 'Cabreader Management',
          group: 'agendas',
          groupRequiresAdmin: true,
          items: [{
            title: 'Agenda Management',
            to: { name: 'AdminAgendaTypesManage' }
          }, {
            title: 'Group Management',
            to: { name: 'AdminAgendaGroupsManage' }
          }]
        }, {
          title: 'Ministries',
          group: 'ministries',
          items: [{
            title: 'Manage Ministries',
            to: { name: 'AdminGroupsManage' }
          }, {
            title: 'Manage Ministry Types',
            to: { name: 'AdminGroupTypesManage' }
          }]
        }, {
          title: 'Users',
          group: 'users',
          items: [{
            title: 'Manage Users',
            to: { name: 'AdminUsersManage' }
          }, {
            title: 'Impersonate User',
            to: { name: 'AdminUsersImpersonate' }
          }]
        }, {
          title: 'Workflows',
          group: 'workflows',
          items: [{
            title: 'Manage Workflow Groups',
            to: { name: 'AdminWorkflowGroupsManage' }
          }, {
            title: 'Manage Templates',
            to: { name: 'AdminWorkflowTemplatesManage' }
          }]
        }, {
          title: 'Site Troubleshooting',
          group: 'troubleshooting',
          items: [{
            title: 'View Logs',
            to: { name: 'AdminLogEventsManage' }
          }, {
            title: 'Regenerate Agenda Packages',
            to: { name: 'AdminPackagesManage' }
          }]
        }
      ]
    })

    return {
      me,
      groups
    }
  },
  components: {
    LayoutNavContent
  }
})
