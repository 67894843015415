





















import { defineComponent } from '@vue/composition-api'
import { useAgendaGroupsMachine } from '@/machines/tableMachine'
import ListingTableMeta from '@/components/ListingTableMeta.vue'
import ListingTable from '@/components/ListingTable.vue'
import ListingPagination from '@/components/ListingPagination.vue'
import ListingFormSearch from '@/components/ListingFormSearch.vue'
import ListingTitle from '@/components/ListingTitle.vue'

export default defineComponent({
  components: {
    ListingTableMeta,
    ListingTable,
    ListingPagination,
    ListingFormSearch,
    ListingTitle
  },
  setup () {
    const { state, send } = useAgendaGroupsMachine()

    return {
      state,
      send
    }
  }
})
