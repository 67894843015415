

























import { defineComponent } from '@vue/composition-api'
import { useMachine } from 'xstate-vue2'
import { sortMapBuild, sortMapExtract, tableMachine } from '@/machines/tableMachine'
import ListingTableMeta from '@/components/ListingTableMeta.vue'
import ListingTable from '@/components/ListingTable.vue'
import ListingPagination from '@/components/ListingPagination.vue'
import ListingFormSearch from '@/components/ListingFormSearch.vue'
import ListingTitle from '@/components/ListingTitle.vue'
import FormAgendaRegenerate from '@/components/forms/FormAgendaRegenerate.vue'
import FormAgendaDelete from '@/components/forms/FormAgendaDelete.vue'
import repositoryAgenda from '@/domain/agendaRepository'

export default defineComponent({
  components: {
    ListingTableMeta,
    ListingTable,
    ListingPagination,
    ListingFormSearch,
    ListingTitle,
    FormAgendaRegenerate,
    FormAgendaDelete
  },
  setup () {
    const { state, send } = useMachine(tableMachine, {
      services: {
        search: async (context: any) => {
          const sortMap = sortMapBuild(context.sort, context.order)
          const results = await repositoryAgenda.search({
            page: context.page,
            itemsPerPage: context.itemsPerPage,
            q: context.searchQuery,
            orderTitle: sortMapExtract(sortMap, 'title'),
            orderAgendaGroupAgendaTypeTitle: sortMapExtract(sortMap, 'template.agendaType.packageType')
          })
          return results
        }
      }
    })
    return {
      state,
      send
    }
  }
})
