
















import { defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useGroups } from '@/composables/useGroups'
import FormUserGroupEdit from '@/components/forms/FormUserGroupEdit.vue'
import { UserGroup } from '@/domain/userGroup'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import repository from '@/domain/userGroupRepository'

export default defineComponent({
  props: ['id'],
  components: {
    BaseIcon,
    FormUserGroupEdit
  },
  setup (props) {
    const { groupForDetail, findForDetail } = useGroups()
    findForDetail(props.id)
    const itemsOriginal = ref()
    const form = reactive<UserGroup>({
      id: undefined,
      title: '',
      formattedTitle: '',
      titleAbbreviation: '',
      type: {
        id: undefined,
        title: ''
      },
      isActive: true
    })
    watch(groupForDetail, () => {
      if (groupForDetail.value) {
        const params = JSON.parse(JSON.stringify(groupForDetail.value))
        Object.assign(form, params)
      }
    })
    const { state, send } = useMachine(promiseMachine, {
      services: {
        invoke: () => repository.update(form)
      },
      actions: {
        onDoneEnter: () => findForDetail(props.id)
      }
    })
    return {
      state,
      send,
      form,
      groupForDetail,
      itemsOriginal
    }
  }
})
