


































































import { defineComponent, reactive, watch } from '@vue/composition-api'
import { promiseMachine } from '@/machines/promiseMachine'
import { useMachine } from 'xstate-vue2'
import { useUser } from '@/composables/useUser'
import { GroupMembership } from '@/domain/groupMembership'
import repository from '@/domain/groupMembershipRepository'

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { findForUserManagement } = useUser()
    const obj = reactive<GroupMembership>(JSON.parse(JSON.stringify(props.item)))
    watch(() => props.item, () => {
      Object.assign(obj, JSON.parse(JSON.stringify(props.item)))
    }, {
      immediate: true
    })
    const { state, send } = useMachine(promiseMachine, {
      services: {
        invoke: async () => {
          const params = JSON.parse(JSON.stringify(obj))
          return repository.update(params)
        }
      },
      actions: {
        onDoneEnter: () => findForUserManagement(props.item.user.id)
      }
    })
    return {
      obj,
      state,
      send
    }
  }
})
