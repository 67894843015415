var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ListingTitle',{attrs:{"type":"Agenda","subtext":"Manages materials distributed via Cabreader."}}),_vm._m(0),_c('v-sheet',{staticClass:"mb-5 pa-5",attrs:{"elevation":"1"}},[_c('v-row',[_c('v-col',[_c('ListingFormSearch',{attrs:{"state":_vm.state,"send":_vm.send}})],1)],1)],1),_c('ListingTableMeta',{attrs:{"state":_vm.state,"send":_vm.send,"route":{ name: 'AdminAgendaTypesManageCreate' },"type":"Agenda Type"}}),_c('ListingTable',{attrs:{"state":_vm.state,"send":_vm.send,"handleRowClick":function (ref) {
  var id = ref.id;

  return _vm.$router.push({ name: 'AdminAgendaTypesManageDetail', params: { id: id } });
},"headers":[
    { text: 'Title', value: 'title' },
    { text: 'Package Type', value: 'packageType' }
  ]},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}}])}),_c('ListingPagination',{attrs:{"state":_vm.state,"send":_vm.send}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-8 text-body-2"},[_c('p',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Agendas")]),_vm._v(" are created for Cabinet, Cabinet Committee/Board Meetings, Weekly meetings. Materials published and distributed for weekly meetings override previous agenda material.")]),_c('p',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Document Bundles")]),_vm._v(" are created for electronic distribution of one-off events or briefing material (i.e. House Binder, Budget Materials). Bundles once published are not overwritten, but are only retained on the system for 12 months.")]),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Steps:")]),_c('ol',[_c('li',{staticClass:"mb-4"},[_vm._v("Create Agenda – Document Bundle Template. Title and choose appropriate type. You can add section headers if you have fixed headings (these are not required and editable).")]),_c('li',{staticClass:"mb-4"},[_vm._v("Create a corresponding Agenda – Document Bundle Group. Title, add users and assign a folder (this is the Sharepoint location your documents can be stored. These can be specific and titled the same as your document bundle or broader like Ministry containing multiple Document Bundles Assigned i.e. IGA).")])]),_c('p',[_vm._v("Steps one and two are administrated by Cabinet Officers only. Docshare users with “agenda” role can now manage agendas and document bundles from their Docshare dashboard.")])])}]

export { render, staticRenderFns }