var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ListingTitle',{attrs:{"type":"Group"}}),_c('v-sheet',{staticClass:"mb-5 pa-5",attrs:{"elevation":"1"}},[_c('v-row',[_c('v-col',[_c('ListingFormSearch',{attrs:{"state":_vm.state,"send":_vm.send}})],1)],1)],1),_c('ListingTableMeta',{attrs:{"state":_vm.state,"send":_vm.send,"route":{ name: 'AdminAgendaGroupsManageCreate' },"type":"Agenda Group"}}),_c('ListingTable',{attrs:{"state":_vm.state,"send":_vm.send,"handleRowClick":function (ref) {
  var id = ref.id;

  return _vm.$router.push({ name: 'AdminAgendaGroupsManageDetail', params: { id: id } });
},"headers":[
    { text: 'Title', value: 'title' },
    { text: 'Folder Name', value: 'documentFolderName' },
    { text: 'Agenda Type', value: 'agendaType.title' } ]}}),_c('ListingPagination',{attrs:{"state":_vm.state,"send":_vm.send}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }