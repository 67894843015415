






import { defineComponent } from '@vue/composition-api'
import FormAgendaTypeEdit from '@/components/forms/FormAgendaTypeEdit.vue'
import DetailPage from '@/components/DetailPage.vue'

export default defineComponent({
  props: ['id'],
  components: {
    FormAgendaTypeEdit,
    DetailPage
  }
})
