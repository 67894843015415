import { render, staticRenderFns } from "./AdminWorkflowTemplatesManageList.vue?vue&type=template&id=6b732060&scoped=true&"
import script from "./AdminWorkflowTemplatesManageList.vue?vue&type=script&lang=ts&"
export * from "./AdminWorkflowTemplatesManageList.vue?vue&type=script&lang=ts&"
import style0 from "./AdminWorkflowTemplatesManageList.vue?vue&type=style&index=0&id=6b732060&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b732060",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VAutocomplete,VCol,VRow,VSheet})
