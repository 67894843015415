





















































































































import { computed, defineComponent, reactive, ref, toRefs, watch } from '@vue/composition-api'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import BaseAutocomplete from '@/components/forms/BaseAutocomplete.vue'
import { notEmpty } from '@/validations'
import { BaseInputText, BaseButton, BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import repository from '@/domain/agendaTemplateRepository'
import repositoryAgendaTypes from '@/domain/agendaTypeRepository'
import { AgendaTemplate } from '@/domain/agendaTemplate'
import { useUser } from '@/composables/useUser'
import ChipBlock from '@/components/ChipBlock.vue'
import ChipUser from '@/components/ChipUser.vue'

export default defineComponent({
  props: ['id'],
  components: {
    BaseCombobox,
    BaseInputText,
    BaseAutocomplete,
    ChipBlock,
    ChipUser,
    BaseButton,
    BaseIcon
  },
  setup (props, { root }) {
    // TODO: migrate to `const store = useStore()`
    const store = root.$store
    const { id } = toRefs(props)
    const detail = computed<AgendaTemplate>(() => store.state.agendaTemplates.detail)
    const agendaTypeSearchPromise = q => repositoryAgendaTypes.findByCriteria({ q })
    const agendaTypeItemText = s => s.title

    const defaults = {
      id: undefined,
      title: '',
      documentFolderName: '',
      agendaType: undefined,
      users: [],
      secretary: []
    }
    const formElement = ref<any>(null)
    const form = reactive<AgendaTemplate>(JSON.parse(JSON.stringify(defaults)))
    // load detail object
    if (id?.value) {
      repository.findById(id.value).then(x => {
        store.commit('agendaTemplates/setForDetail', x)
      })
    }

    const selectedUser = ref<any>(null)
    const removeUser = (id) => {
      if (form.users && form.users?.length) {
        form.users = form.users.filter(x => x.id !== id)
      }
    }
    const appendUser = () => {
      if (selectedUser.value && typeof selectedUser.value !== 'string' && selectedUser.value.id) {
        if (form.users && form.users?.length) {
          form.users.push(selectedUser.value)
        } else {
          form.users = [selectedUser.value]
        }

        selectedUser.value = null
      }
    }

    const selectedAdmin = ref<any>(null)
    const removeAdmin = (id) => {
      if (form.secretary && form.secretary?.length) {
        form.secretary = form.secretary.filter(x => x.id !== id)
      }
    }
    const appendAdmin = () => {
      if (selectedAdmin.value && typeof selectedAdmin.value !== 'string' && selectedAdmin.value.id) {
        if (form.secretary && form.secretary?.length) {
          form.secretary.push(selectedAdmin.value)
        } else {
          form.secretary = [selectedAdmin.value]
        }

        selectedAdmin.value = null
      }
    }

    const getKey = (prefix, id) => {
      return prefix + '-' + id
    }

    // If detail changes
    watch(detail, () => {
      if (detail.value) {
        // assign detail to form
        const params = JSON.parse(JSON.stringify(detail.value))
        Object.assign(form, params)
      }
    })
    const { state, send } = useMachine(promiseMachine, {
      services: {
        invoke: () => {
          if (id?.value) {
            return repository.update(form)
          }
          return repository.create(form)
        }
      },
      actions: {
        onDoneEnter: () => {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
          formElement.value.reset()
          formElement.value.resetValidation()
          if (id?.value) {
            // refresh entity on manage page
            repository.findById(id.value).then(x => {
              store.commit('agendaTemplates/setForDetail', x)
            })
          } else {
            // clear form fields
            Object.assign(form, JSON.parse(JSON.stringify(defaults)))
          }
        }
      }
    })
    const valid = ref(true)
    const { search, selectText } = useUser()
    return {
      valid,
      notEmpty,
      formElement,
      form,
      state,
      send,
      agendaTypeSearchPromise,
      agendaTypeItemText,
      search,
      selectText,
      removeUser,
      appendUser,
      selectedUser,
      selectedAdmin,
      removeAdmin,
      appendAdmin,
      getKey
    }
  }
})
