import { UserUserGroupApi } from '@zucommunications/gsk-docshare-web-api'
import axios from '@/utils/axios'
import { ICrudData } from '@/domain/interfaces'
import { GroupMembershipMapper } from '@/domain/groupMembershipMapper'
import { GroupMembership } from '@/domain/groupMembership'
import RepositoryError from '@/domain/repositoryError'

const BASE_PATH = window.Cypress ? '' : process.env.VUE_APP_SYMFONY_API_URL
const api = new UserUserGroupApi(undefined, BASE_PATH, axios)

class GroupMembershipRepository implements ICrudData<GroupMembership> {
  async create (entity: GroupMembership) {
    try {
      const params = GroupMembershipMapper.serializePost(entity)
      const response = await api.postUserUserGroupCollection(params)
      return GroupMembershipMapper.deserializePatch(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async update (entity: GroupMembership) {
    try {
      const params = GroupMembershipMapper.serializePatch(entity)
      const response = await api.patchUserUserGroupItem(entity.id || '', params)
      return GroupMembershipMapper.deserializePatch(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async delete (id) {
    try {
      const response = await api.deleteUserUserGroupItem(id)
      return response.data
    } catch (err) {
      throw new RepositoryError(err)
    }
  }
}

export default new GroupMembershipRepository()
