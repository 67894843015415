
















import { defineComponent, reactive } from '@vue/composition-api'
import { BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import FormUserGroupEdit from '@/components/forms/FormUserGroupEdit.vue'
import { UserGroup } from '@/domain/userGroup'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import repository from '@/domain/userGroupRepository'

export default defineComponent({
  components: {
    BaseIcon,
    FormUserGroupEdit
  },
  setup () {
    const form = reactive<UserGroup>({
      id: undefined,
      title: '',
      formattedTitle: '',
      titleAbbreviation: '',
      type: {
        id: undefined,
        title: ''
      },
      isActive: true
    })
    const { state, send } = useMachine(promiseMachine, {
      services: {
        invoke: () => repository.create(JSON.parse(JSON.stringify(form)))
      },
      actions: {
        onDoneEnter: () => {
          // clear form
          // success
        }
      }
    })
    return {
      state,
      send,
      form
    }
  }
})
