import { computed } from '@vue/composition-api'
import store from '@/store'
import { WorkflowTemplate } from '@/domain/workflowTemplate'
import repository from '@/domain/workflowTemplateRepository'

export function useWorkflowTemplates () {
  const findForDetail = id => (
    repository.findById(id).then(x => {
      store.commit('workflowTemplates/setForDetail', x)
    })
  )
  const workflowTemplateForDetail = computed<WorkflowTemplate>(() => store.state.workflowTemplates.detail)
  return {
    findForDetail,
    workflowTemplateForDetail
  }
}
