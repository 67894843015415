import { computed } from '@vue/composition-api'
import store from '@/store'
import { UserGroupType } from '@/domain/userGroupType'
import repository from '@/domain/userGroupTypeRepository'

export function useGroupTypes () {
  const findAll = () => (
    repository.findByCriteria({ page: 1, itemsPerPage: 1000 }).then(x => {
      store.commit('groupTypes/setTypes', x)
    })
  )
  const findForDetail = id => (
    repository.findById(id).then(x => {
      store.commit('groupTypes/setForDetail', x)
    })
  )
  const groupTypes = computed<Array<UserGroupType>>(() => store.state.groupTypes.types)
  const groupTypeForDetail = computed<UserGroupType>(() => store.state.groupTypes.detail)
  return {
    findAll,
    findForDetail,
    groupTypes,
    groupTypeForDetail
  }
}
