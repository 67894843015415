








































































































































































































import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'
import draggable from 'vuedraggable'
import { UserGroupMapper } from '@/domain/userGroupMapper'
import { UserGroupTypeMapper } from '@/domain/userGroupTypeMapper'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import ChipBlock from '@/components/ChipBlock.vue'
import ChipUser from '@/components/ChipUser.vue'
import GroupHeading from '@/components/GroupHeading.vue'
import OverlayLoading from '@/components/OverlayLoading.vue'
import { useGroupTypes } from '@/composables/useGroupTypes'
import FormWorkflowTemplateDelete from '@/components/forms/FormWorkflowTemplateDelete.vue'
import { notEmpty } from '@/validations'
import {
  BaseInputText,
  BaseInputSelect,
  BaseButton,
  BaseIcon
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import repositoryWorkflowTemplate from '@/domain/workflowTemplateRepository'
import repositoryUserGroup from '@/domain/userGroupRepository'
import documentTemplateRepository from '@/domain/documentTemplateRepository'

export default defineComponent({
  props: ['form', 'state', 'send'],
  components: {
    BaseCombobox,
    ChipBlock,
    ChipUser,
    GroupHeading,
    OverlayLoading,
    FormWorkflowTemplateDelete,
    BaseInputText,
    BaseInputSelect,
    BaseButton,
    BaseIcon,
    draggable
  },
  setup (props, { root }) {
    const store = root.$store
    // User Group
    const userGroupSearchPromise = q => repositoryUserGroup.findByCriteria({ q })
    const userGroupItemText = s => s.title

    // User Group Types
    const { groupTypes, findAll } = useGroupTypes()
    findAll()

    // Document Templates
    const existingExceptionDocumentTemplates: any = ref([])
    const documentTemplatesGrouped = computed(() => store.getters['documentTemplates/getTemplatesByTypeOrdered'])
    onMounted(() => {
      documentTemplateRepository.findByCriteria({}).then(x => {
        store.commit('documentTemplates/setTemplates', x)
      })
    })

    const checkDocumentTemplateGroup = (group) => {
      const groupTemplates = group[1].map(x => Number(x.listItemId))
      if (groupTemplates.every(y => props.form.workflowTemplateDocuments.some(z => z.templateSpId === y))) {
        return { result: true, partial: false }
      } else if (groupTemplates.some(y => props.form.workflowTemplateDocuments.some(z => z.templateSpId === y))) {
        return { result: true, partial: true }
      } else {
        return { result: false, partial: true }
      }
    }

    const checkExistingDocumentTemplateGroup = (group) => {
      const groupTemplates = group[1].map(x => Number(x.listItemId))
      if (groupTemplates.every(y => existingExceptionDocumentTemplates.value.some(z => z === y))) {
        return true
      }
      return false
    }

    const updateDocumentTemplateGroup = (group, event) => {
      const groupTemplates = group[1].map(x => Number(x.listItemId))
      if (event) {
        groupTemplates.forEach(x => {
          if (!props.form.workflowTemplateDocuments.some(y => y.templateSpId === x) && !existingExceptionDocumentTemplates.value.some(y => y === x)) {
            props.form.workflowTemplateDocuments.push({ templateSpId: x })
          }
        })
      } else {
        props.form.workflowTemplateDocuments = props.form.workflowTemplateDocuments.filter(x => !groupTemplates.includes(x.templateSpId))
      }
    }

    const updateDocumentTemplateGroupTemplate = (template, event) => {
      if (event) {
        props.form.workflowTemplateDocuments.push({ templateSpId: Number(template.listItemId) })
      } else {
        props.form.workflowTemplateDocuments = props.form.workflowTemplateDocuments.filter(x => Number(template.listItemId) !== x.templateSpId)
      }
    }

    // Workflow Groups
    const loadingWorkflowGroups = ref(false)
    const setWorkflowGroups = async () => {
      existingExceptionDocumentTemplates.value = []
      documentTemplatesGrouped.value.forEach(x => {
        props.form.workflowTemplateDocuments.push(...x[1].map(y => {
          return { templateSpId: Number(y.listItemId) }
        }))
      })

      if (props.form.workflowType && (props.form.userGroup || props.form.userGroupType)) {
        loadingWorkflowGroups.value = true

        const workflowTemplates = await repositoryWorkflowTemplate.findByCriteria({
          itemsPerPage: 1000, // Load all workflow groups
          userGroup: props.form.userGroup ? UserGroupMapper.serializeIri(props.form.userGroup) : undefined,
          userGroupType: props.form.userGroupType ? UserGroupTypeMapper.serializeIri(props.form.userGroupType) : undefined,
          workflowType: props.form.workflowType
        })

        if (workflowTemplates && workflowTemplates.length) {
          const defaultTemplate = workflowTemplates.filter(x => x.isDefault === true)[0]

          workflowTemplates.forEach(x => {
            if (!x.isDefault) {
              return x.workflowTemplateDocuments?.forEach(y => {
                existingExceptionDocumentTemplates.value.push(y.templateSpId)
              })
            }
          })
          // Filter out document templates used in other workflow templates
          if (existingExceptionDocumentTemplates.value.length) {
            props.form.workflowTemplateDocuments = props.form.workflowTemplateDocuments.filter(x => !existingExceptionDocumentTemplates.value.includes(x.templateSpId))
          }

          if (defaultTemplate && (defaultTemplate.nonCriticalWorkflowGroups || defaultTemplate.criticalWorkflowGroups)) {
            props.form.nonCriticalWorkflowGroups = defaultTemplate.nonCriticalWorkflowGroups
            props.form.criticalWorkflowGroups = defaultTemplate.criticalWorkflowGroups
          }
        }

        loadingWorkflowGroups.value = false
      }
    }

    const updateFormProperty = async (event) => {
      const { property, value } = event
      props.form[property] = value
      await setWorkflowGroups()
    }

    const updateTemplateGroup = (group, event) => {
      if (event) {
        group.isEnabled = event
        group.templateUsers = group.workflowGroup.users
      } else {
        group.isEnabled = event
        group.templateUsers = []
      }
    }

    const updateTemplateGroupUser = (user, group, event) => {
      if (event) {
        group.templateUsers.push(user)
        if (!group.isEnabled) {
          group.isEnabled = event
        }
      } else {
        group.templateUsers = group.templateUsers.filter(x => x.id !== user.id)
        if (group.templateUsers.length === 0) {
          group.isEnabled = event
        }
      }
    }

    const valid = ref(true)

    const dragOptions = {
      animation: 200,
      disabled: false,
      group: 'workflowGroups',
      ghostClass: 'ghost'
    }

    return {
      notEmpty,
      userGroupSearchPromise,
      userGroupItemText,
      groupTypes,
      documentTemplatesGrouped,
      existingExceptionDocumentTemplates,
      checkDocumentTemplateGroup,
      checkExistingDocumentTemplateGroup,
      updateDocumentTemplateGroup,
      updateDocumentTemplateGroupTemplate,
      loadingWorkflowGroups,
      updateFormProperty,
      updateTemplateGroup,
      updateTemplateGroupUser,
      valid,
      dragOptions
    }
  }
})
