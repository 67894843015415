








































import { computed, watch, defineComponent } from '@vue/composition-api'
import { BaseButton, BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import repository from '@/domain/logEventRepository'
import CardMini from '@/components/CardMini.vue'
import LogEventChip from '@/components/LogEventChip.vue'

export default defineComponent({
  props: ['id'],
  components: {
    CardMini,
    BaseButton,
    BaseIcon,
    LogEventChip
  },
  setup (props, { root }: any) {
    const store = root.$store
    const log = computed(() => store.state.logEvents.detail)
    watch(() => props.id, () => {
      repository.findById(props.id).then(x => {
        store.commit('logEvents/setForDetail', x)
      })
    }, {
      immediate: true
    })
    return {
      log
    }
  }
})
