import { render, staticRenderFns } from "./FormWorkflowGroupEdit.vue?vue&type=template&id=30d7e47b&scoped=true&"
import script from "./FormWorkflowGroupEdit.vue?vue&type=script&lang=ts&"
export * from "./FormWorkflowGroupEdit.vue?vue&type=script&lang=ts&"
import style0 from "./FormWorkflowGroupEdit.vue?vue&type=style&index=0&id=30d7e47b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30d7e47b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VAlert,VCheckbox,VForm})
