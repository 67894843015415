import { computed } from '@vue/composition-api'
import store from '@/store'
import { UserGroup } from '@/domain/userGroup'
import repository from '@/domain/userGroupRepository'

export function useGroups () {
  const findForDetail = id => (
    repository.findById(id).then(x => {
      store.commit('groups/setForDetail', x)
    })
  )
  const groupForDetail = computed<UserGroup>(() => store.state.groups.detail)
  return {
    findForDetail,
    groupForDetail
  }
}
