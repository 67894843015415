

























import { defineComponent } from '@vue/composition-api'
import { useMinistriesMachine } from '@/machines/tableMachine'
import ListingTableMeta from '@/components/ListingTableMeta.vue'
import ListingTable from '@/components/ListingTable.vue'
import ListingPagination from '@/components/ListingPagination.vue'
import ListingFormSearch from '@/components/ListingFormSearch.vue'
import ListingTitle from '@/components/ListingTitle.vue'
import ChipBoolean from '@/components/ChipBoolean.vue'

export default defineComponent({
  components: {
    ListingTableMeta,
    ListingTable,
    ListingPagination,
    ListingFormSearch,
    ListingTitle,
    ChipBoolean
  },
  setup (_, { root }) {
    const { state, send } = useMinistriesMachine()

    const handleRowClick = ({ id }) => {
      root.$router.push({ name: 'AdminGroupsManageDetail', params: { id } })
    }
    return {
      handleRowClick,
      state,
      send
    }
  }
})
