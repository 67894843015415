

































































import { defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { BaseIcon, BaseButton, BaseChipStatus } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import GroupHeading from '@/components/GroupHeading.vue'
import FormUserGroupMembershipEdit from '@/components/forms/FormUserGroupMembershipEdit.vue'
import FormUserGroupMembershipCreate from '@/components/forms/FormUserGroupMembershipCreate.vue'
import FormUserGroupMembershipDelete from '@/components/forms/FormUserGroupMembershipDelete.vue'
import FormUserEdit from '@/components/forms/FormUserEdit.vue'
import ChipBoolean from '@/components/ChipBoolean.vue'
import { useUser } from '@/composables/useUser'
import Vue from 'vue'
import { GroupMembership } from '@/domain/groupMembership'
import { User } from '@/domain/user'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import repository from '@/domain/userRepository'

export default defineComponent({
  props: ['id'],
  components: {
    BaseIcon,
    BaseButton,
    ChipBoolean,
    GroupHeading,
    BaseChipStatus,
    BaseCombobox,
    FormUserGroupMembershipEdit,
    FormUserGroupMembershipCreate,
    FormUserGroupMembershipDelete,
    FormUserEdit
  },
  setup (props, { root }) {
    const store = root.$store

    const { selectText, userForUserManagement: user, findForUserManagement } = useUser()
    findForUserManagement(props.id)
    store.commit('users/setUserForUserManagement', undefined)

    const expanded = ref<Array<GroupMembership>>([])
    const removeFromExpanded = ({ id }) => {
      expanded.value = expanded.value.filter(x => x.id !== id)
    }
    const showForm = ref(false)

    const toggleForm = () => {
      showForm.value = !showForm.value
      if (showForm.value) {
        Vue.nextTick(() => root.$vuetify.goTo('#form'))
      }
    }

    const form = reactive<User>({
      id: undefined,
      firstName: '',
      lastName: '',
      email: '',
      rolesEditable: []
    })
    watch(user, () => {
      if (user.value) {
        const params = JSON.parse(JSON.stringify(user.value))
        Object.assign(form, params)
      }
    })
    const { state, send } = useMachine(promiseMachine, {
      services: {
        invoke: () => {
          form.roles = form.rolesEditable
          return repository.update(form)
        }
      }
    })

    return {
      selectText,
      expanded,
      user,
      removeFromExpanded,
      showForm,
      toggleForm,
      form,
      state,
      send
    }
  }
})
