
























import { defineComponent } from '@vue/composition-api'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import DialogConfirm from '@/components/DialogConfirm.vue'
import repository from '@/domain/workflowGroupRepository'

export default defineComponent({
  components: {
    DialogConfirm
  },
  props: ['id'],
  setup (_, { root }) {
    const { state, send } = useMachine(promiseMachine, {
      context: {
        needsConfirmation: true
      },
      services: {
        invoke: (_, event: any) => repository.delete(event.value)
      },
      actions: {
        onDoneEnter: () => {
          root.$router.push({ name: 'AdminWorkflowGroupsManageList' })
        }
      }
    })
    return {
      state, send
    }
  }
})
