






























import { defineComponent } from '@vue/composition-api'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import DialogConfirm from '@/components/DialogConfirm.vue'
import { BaseIcon, BaseButton } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useUser } from '@/composables/useUser'
import repositoryGroupMembership from '@/domain/groupMembershipRepository'

export default defineComponent({
  components: {
    DialogConfirm,
    BaseIcon,
    BaseButton
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { findForUserManagement } = useUser()
    const { state, send } = useMachine(promiseMachine, {
      context: {
        needsConfirmation: true
      },
      services: {
        invoke: async (context, event: any) => {
          await repositoryGroupMembership.delete(event.value)
        }
      },
      actions: {
        onDoneEnter: () => findForUserManagement(props.item.user.id)
      }
    })
    return {
      state, send
    }
  }
})
