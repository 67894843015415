



















































































import { computed, defineComponent, reactive, ref, toRefs, watch } from '@vue/composition-api'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import { notEmpty } from '@/validations'
import {
  BaseInputText,
  BaseInputSelect,
  BaseAlert,
  BaseButton,
  BaseIcon
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import repository from '@/domain/agendaTypeRepository'
import { AgendaType } from '@/domain/agendaType'
import draggable from 'vuedraggable'
import { getRandomInt } from '@/utils/random'
import FormAgendaTypeDelete from '@/components/forms/FormAgendaTypeDelete.vue'

export default defineComponent({
  props: ['id'],
  components: {
    FormAgendaTypeDelete,
    BaseCombobox,
    BaseInputText,
    BaseInputSelect,
    BaseAlert,
    BaseButton,
    BaseIcon,
    draggable
  },
  setup (props, { root }) {
    // TODO: migrate to `const store = useStore()`
    const store = root.$store
    const { id } = toRefs(props)
    const detail = computed<AgendaType>(() => store.state.agendaTypes.detail)
    const defaults = {
      id: undefined,
      title: '',
      packageType: '',
      sections: []
    }
    const formElement = ref<any>(null)
    const form = reactive<AgendaType>(JSON.parse(JSON.stringify(defaults)))
    if (id?.value) {
      // load detail
      repository.findById(id.value).then(x => {
        store.commit('agendaTypes/setForDetail', x)
      })
    }
    watch(detail, () => {
      // if detail changes
      if (detail.value) {
        // assign detail to form
        const params = JSON.parse(JSON.stringify(detail.value))
        Object.assign(form, params)
      }
    })
    const { state, send } = useMachine(promiseMachine, {
      services: {
        invoke: () => {
          if (id?.value) {
            return repository.update(form)
          }
          return repository.create(form)
        }
      },
      actions: {
        onDoneEnter: () => {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
          formElement.value.reset()
          formElement.value.resetValidation()
          if (id?.value) {
            // refresh entity on manage page
            repository.findById(id.value).then(x => {
              store.commit('agendaTypes/setForDetail', x)
            })
          } else {
            // clear form fields
            Object.assign(form, JSON.parse(JSON.stringify(defaults)))
          }
        }
      }
    })
    const valid = ref(true)
    const dragOptions = reactive({
      animation: 200,
      group: 'description',
      disabled: false,
      ghostClass: 'ghost',
      filter: '.ui-title-field',
      preventOnFilter: false
    })
    const addSection = () => {
      if (form.sections) {
        const id = getRandomInt(10000, 20000)
        form.sections = [...form.sections, { id, title: '', position: undefined }]
      }
    }
    const removeSection = (i) => {
      return form.sections?.splice(i, 1)
    }
    return {
      valid,
      notEmpty,
      formElement,
      form,
      state,
      send,
      addSection,
      removeSection,
      dragOptions
    }
  }
})
