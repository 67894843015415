


















import { defineComponent, reactive, watch } from '@vue/composition-api'
import { BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import FormWorkflowTemplateEdit from '@/components/forms/FormWorkflowTemplateEdit.vue'
import { useWorkflowTemplates } from '@/composables/useWorkflowTemplates'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import { WorkflowTemplate } from '@/domain/workflowTemplate'
import repository from '@/domain/workflowTemplateRepository'

export default defineComponent({
  props: ['id'],
  components: {
    BaseIcon,
    FormWorkflowTemplateEdit
  },
  setup (props) {
    const { findForDetail, workflowTemplateForDetail } = useWorkflowTemplates()
    findForDetail(props.id)
    const form = reactive<WorkflowTemplate>({
      id: undefined,
      userGroup: undefined,
      userGroupType: undefined,
      workflowType: undefined,
      nonCriticalWorkflowGroups: [],
      criticalWorkflowGroups: [],
      workflowTemplateDocuments: [],
      isDefault: false
    })
    watch(workflowTemplateForDetail, () => {
      if (workflowTemplateForDetail.value) {
        const params = JSON.parse(JSON.stringify(workflowTemplateForDetail.value))
        Object.assign(form, params)
      }
    })
    const { state, send } = useMachine(promiseMachine, {
      services: {
        invoke: () => repository.update(form)
      },
      actions: {
        onDoneEnter: () => {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
          findForDetail(props.id)
        }
      }
    })
    return {
      state,
      send,
      form
    }
  }
})
