






























































































import { defineComponent, ref } from '@vue/composition-api'
import { useUsersMachine, sortMapBuild, sortMapExtract } from '@/machines/tableMachine'
import { useUser } from '@/composables/useUser'
import repository from '@/domain/userRepository'
import repositoryUserGroup from '@/domain/userGroupRepository'
import { UserGroupMapper } from '@/domain/userGroupMapper'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import ChipBoolean from '@/components/ChipBoolean.vue'
import ListingTableMeta from '@/components/ListingTableMeta.vue'
import ListingTable from '@/components/ListingTable.vue'
import ListingPagination from '@/components/ListingPagination.vue'
import ListingFormSearch from '@/components/ListingFormSearch.vue'
import ListingTitle from '@/components/ListingTitle.vue'
import { BaseInputSelect, BaseButton } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'

export default defineComponent({
  components: {
    BaseCombobox,
    ChipBoolean,
    ListingTableMeta,
    ListingTable,
    ListingPagination,
    ListingFormSearch,
    ListingTitle,
    BaseInputSelect,
    BaseButton
  },
  setup (_, { root }) {
    const { state, send } = useUsersMachine()

    const getUserGroup = user => {
      return user.groupMembership?.map(x => x.group?.formattedTitle).join(', ')
    }
    const getTitle = user => {
      return user.groupMembership?.map(x => x.title).join(', ')
    }
    const { selectText } = useUser()

    // User Group
    const userGroupSearchPromise = q => repositoryUserGroup.findByCriteria({ q })
    const userGroupItemText = s => s.title

    // Export user csv
    const exportUserCsvLoading = ref(false)
    const exportUserCsv = async () => {
      exportUserCsvLoading.value = true
      let userGroup, userGroup2
      const sortMap = sortMapBuild(state.value.context.sort, state.value.context.order)

      if (state.value.context.userGroup && state.value.context.userGroup.length) {
        if (state.value.context.userGroup.length > 1) {
          userGroup2 = state.value.context.userGroup.map(x => UserGroupMapper.serializeIri(x))
        } else {
          userGroup = UserGroupMapper.serializeIri(state.value.context.userGroup[0])
        }
      }

      await repository.search({
        pagination: false,
        userGroup,
        userGroup2,
        active: state.value.context.isActive,
        c: state.value.context.searchQuery,
        orderGivenName: sortMapExtract(sortMap, 'name'),
        orderSurName: sortMapExtract(sortMap, 'name'),
        orderEmail: sortMapExtract(sortMap, 'email'),
        orderActive: sortMapExtract(sortMap, 'isActive')
      }).then(users => {
        const replacer = (_, value) => value === null ? '' : value
        const headers = ['firstName', 'lastName', 'email', 'ministry', 'title', 'isActive']
        const csv = [
          headers.join(','), // Header row first
          ...users.results.map(user => headers.map(fieldName => {
            let value = user[fieldName]
            if (fieldName === 'ministry') {
              value = getUserGroup(user)
            } else if (fieldName === 'title') {
              value = getTitle(user)
            }
            return JSON.stringify(value, replacer)
          }).join(','))
        ].join('\r\n')

        // Create temporary link to download csv
        const urlCreator = window.URL || window.webkitURL
        const link = document.createElement('a')
        link.href = urlCreator.createObjectURL(new Blob(['\ufeff', csv]))
        link.download = 'users.csv'
        link.click()

        // Clear temporary link created
        urlCreator.revokeObjectURL(link.href)
        link.remove()
      }).catch(console.error)

      exportUserCsvLoading.value = false
    }

    const handleRowClick = user => {
      root.$router.push({ name: 'AdminUsersManageDetail', params: { id: user.id } })
    }
    return {
      getUserGroup,
      getTitle,
      state,
      send,
      selectText,
      userGroupSearchPromise,
      userGroupItemText,
      exportUserCsvLoading,
      exportUserCsv,
      handleRowClick
    }
  }
})
