






import { defineComponent, computed, toRefs } from '@vue/composition-api'

export default defineComponent({
  name: 'log-event-chip',
  props: ['log'],
  setup (props) {
    const { log } = toRefs(props)
    const color = computed(() => {
      const level = log?.value?.level
      let badgeColor = 'secondaryGrey'
      badgeColor = (['Emergency', 'Alert', 'Critical', 'Error'].includes(level)) ? 'secondaryRed' : badgeColor
      badgeColor = (['Warning'].includes(level)) ? 'secondaryOrange' : badgeColor
      badgeColor = (['Notice', 'Informational'].includes(level)) ? 'secondaryGreen' : badgeColor
      return badgeColor
    })
    const textColor = computed(() => {
      return (['Emergency', 'Alert', 'Critical', 'Error', 'Debug'].includes(log?.value?.level)) ? 'white' : 'black'
    })

    return {
      color,
      textColor
    }
  }
})
