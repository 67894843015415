

















import { defineComponent, reactive } from '@vue/composition-api'
import { BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import FormUserInvite from '@/components/forms/FormUserInvite.vue'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import { User } from '@/domain/user'
import repository from '@/domain/userRepository'

export default defineComponent({
  components: {
    BaseIcon,
    FormUserInvite
  },
  setup () {
    const form = reactive<User>({
      email: undefined
    })
    const { state, send } = useMachine(promiseMachine, {
      context: {
        needsConfirmation: true
      },
      services: {
        invoke: () => repository.invite(JSON.parse(JSON.stringify(form)))
      },
      actions: {
        onDoneEnter: () => {
          form.email = undefined
        }
      }
    })
    return {
      state,
      send,
      form
    }
  }
})
