import { computed } from '@vue/composition-api'
import store from '@/store'
import { WorkflowGroup } from '@/domain/workflowGroup'
import repository from '@/domain/workflowGroupRepository'

export function useWorkflowGroups () {
  const findForDetail = id => (
    repository.findById(id).then(x => {
      store.commit('workflowGroups/setForDetail', x)
    })
  )
  const detail = computed<WorkflowGroup>(() => store.state.workflowGroups.detail)
  return {
    findForDetail,
    detail
  }
}
