import { computed } from '@vue/composition-api'
import store from '@/store'
import { AgendaTemplate } from '@/domain/agendaTemplate'
import repository from '@/domain/agendaTemplateRepository'

export function useAgendaTemplates () {
  const findForAgendaTypes = type => (
    repository.findByCriteria({ pagination: false, agendaType: type }).then(x => {
      store.commit('agendaTemplates/setForAgendaTypes', x)
    })
  )
  const groupsForAgendaTypes = computed<AgendaTemplate>(() => store.state.agendaTemplates.agendaTypes)
  return {
    findForAgendaTypes,
    groupsForAgendaTypes
  }
}
