






























import { defineComponent } from '@vue/composition-api'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import DialogConfirm from '@/components/DialogConfirm.vue'
import { BaseIcon, BaseButton } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import agendaRepository from '@/domain/agendaRepository'
import { mdiAutorenew } from '@mdi/js'
import ButtonAction from '@/components/ButtonAction.vue'

export default defineComponent({
  components: {
    DialogConfirm,
    BaseIcon,
    BaseButton,
    ButtonAction
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup () {
    const { state, send } = useMachine(promiseMachine, {
      context: {
        needsConfirmation: true
      },
      services: {
        invoke: async (context, event: any) => agendaRepository.publish({ obj: event.value, forceRebuild: true })
      }
    })
    return {
      state,
      send,
      mdiAutorenew
    }
  }
})
