
































import { defineComponent, ref } from '@vue/composition-api'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import { notEmpty } from '@/validations'
import {
  BaseInputText
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'

export default defineComponent({
  props: ['form', 'state', 'send'],
  components: {
    BaseCombobox,
    BaseInputText
  },
  setup () {
    const valid = ref(true)
    return {
      valid,
      notEmpty
    }
  }
})
