


















import { defineComponent, reactive } from '@vue/composition-api'
import { BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import FormWorkflowGroupEdit from '@/components/forms/FormWorkflowGroupEdit.vue'
import { WorkflowGroup } from '@/domain/workflowGroup'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import repository from '@/domain/workflowGroupRepository'

export default defineComponent({
  components: {
    BaseIcon,
    FormWorkflowGroupEdit
  },
  setup (_, { root }) {
    const form = reactive<WorkflowGroup>({
      id: undefined,
      position: undefined,
      title: '',
      users: undefined,
      userGroup: undefined,
      userGroupType: undefined,
      workflowType: '',
      critical: false
    })
    const { state, send } = useMachine(promiseMachine, {
      services: {
        invoke: () => repository.create(JSON.parse(JSON.stringify(form)))
      },
      actions: {
        onDoneEnter: (context, event) => {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
          const workflowGroup: WorkflowGroup = event.data
          root.$router.push({ name: 'AdminWorkflowGroupsManageDetail', params: { id: String(workflowGroup.id) } })
        }
      }
    })
    return {
      state,
      send,
      form
    }
  }
})
