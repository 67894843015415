




































































import { defineComponent, reactive } from '@vue/composition-api'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import { promiseMachine } from '@/machines/promiseMachine'
import { useMachine } from 'xstate-vue2'
import { isNotString } from '@/validations'
import { useUser } from '@/composables/useUser'
import { GroupMembership } from '@/domain/groupMembership'
import repositoryUserGroup from '@/domain/userGroupRepository'
import repositoryGroupMembership from '@/domain/groupMembershipRepository'

export default defineComponent({
  props: {
    item: {
      type: [Number, String],
      required: true
    }
  },
  components: {
    BaseCombobox
  },
  setup (props) {
    const newGroupMembership = userId => {
      return {
        title: '',
        user: {
          id: +userId
        },
        group: undefined,
        permissions: {
          isAdmin: false,
          isHighRanking: false,
          canAccessAllDocuments: false
        }
      }
    }
    const { findForUserManagement } = useUser()
    const obj = reactive<GroupMembership>(newGroupMembership(+props.item))
    const { state, send } = useMachine(promiseMachine, {
      services: {
        invoke: async () => {
          return repositoryGroupMembership.create(JSON.parse(JSON.stringify(obj)))
        }
      },
      actions: {
        onDoneEnter: () => {
          findForUserManagement(props.item)
          Object.assign(obj, newGroupMembership(+props.item))
        }
      }
    })
    const searchPromise = q => repositoryUserGroup.findByCriteria({ q })
    const itemText = x => x.formattedTitle
    return {
      obj,
      state,
      send,
      searchPromise,
      itemText,
      isNotString
    }
  }
})
