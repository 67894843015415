

































































































import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import ChipBoolean from '@/components/ChipBoolean.vue'
import {
  BaseButton,
  BaseIcon,
  BaseInputSelect
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useWorkflowTemplatesMachine } from '@/machines/tableMachine'
import { useGroupTypes } from '@/composables/useGroupTypes'
import repositoryUserGroup from '@/domain/userGroupRepository'
import documentTemplateRepository from '@/domain/documentTemplateRepository'
import ListingTableMeta from '@/components/ListingTableMeta.vue'
import ListingTable from '@/components/ListingTable.vue'
import ListingPagination from '@/components/ListingPagination.vue'
import ListingFormSearch from '@/components/ListingFormSearch.vue'
import ListingTitle from '@/components/ListingTitle.vue'
import { useUser } from '@/composables/useUser'

export default defineComponent({
  components: {
    BaseCombobox,
    ChipBoolean,
    BaseButton,
    BaseIcon,
    BaseInputSelect,
    ListingTableMeta,
    ListingTable,
    ListingPagination,
    ListingFormSearch,
    ListingTitle
  },
  setup (_, { root }) {
    // TODO: migrate to `const store = useStore()`
    const store = root.$store
    const { me } = useUser()
    const isGroupAdmin = computed(() => Boolean(me.value.isGroupAdmin && !me.value.isAdmin))

    const { state, send } = useWorkflowTemplatesMachine()

    const workflowTemplates = ref(null)

    // Document Templates
    const documentTemplatesGrouped = computed(() => store.getters['documentTemplates/getTemplatesByTypeOrdered'])
    onMounted(() => {
      documentTemplateRepository.findByCriteria({}).then(x => {
        store.commit('documentTemplates/setTemplates', x)
      })
    })

    // User Group
    const userGroupSearchPromise = q => repositoryUserGroup.findByCriteria({ q })
    const userGroupItemText = s => s.title

    // User Group Types
    const { groupTypes, findAll } = useGroupTypes()
    if (!isGroupAdmin.value) {
      findAll()
    }

    const userGroupTypeItemText = s => s.title
    const userGroupTypeItemValue = s => `/api/user_group_types/${s.id}`

    const getTemplateDocumentCategories = group => {
      const categories: any = []
      documentTemplatesGrouped.value.forEach(x => {
        if (x[1].some(y => group.workflowTemplateDocuments.some(z => z.templateSpId === Number(y.listItemId)))) {
          categories.push(x[0])
        }
      })
      return categories.length ? categories.join(', ') : '-'
    }

    const getUserGroup = group => {
      return group.userGroup?.formattedTitle || '-'
    }

    const getUserGroupType = group => {
      return group.userGroupType?.title || '-'
    }

    const handleRowClick = ({ id }) => {
      root.$router.push({ name: 'AdminWorkflowTemplatesManageDetail', params: { id } })
    }

    return {
      isGroupAdmin,
      state,
      send,
      workflowTemplates,
      userGroupSearchPromise,
      userGroupItemText,
      groupTypes,
      userGroupTypeItemText,
      userGroupTypeItemValue,
      getTemplateDocumentCategories,
      getUserGroup,
      getUserGroupType,
      handleRowClick
    }
  }
})
