
















import { defineComponent, reactive, watch } from '@vue/composition-api'
import { BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import FormGroupTypeEdit from '@/components/forms/FormGroupTypeEdit.vue'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import { useGroupTypes } from '@/composables/useGroupTypes'
import { UserGroupType } from '@/domain/userGroupType'
import repository from '@/domain/userGroupTypeRepository'

export default defineComponent({
  props: ['id'],
  components: {
    BaseIcon,
    FormGroupTypeEdit
  },
  setup (props) {
    const { groupTypeForDetail, findForDetail } = useGroupTypes()
    findForDetail(props.id)
    const form = reactive<UserGroupType>({
      id: undefined,
      title: ''
    })
    watch(groupTypeForDetail, () => {
      if (groupTypeForDetail.value) {
        const params = JSON.parse(JSON.stringify(groupTypeForDetail.value))
        Object.assign(form, params)
      }
    })
    const { state, send } = useMachine(promiseMachine, {
      services: {
        invoke: () => repository.update(form)
      },
      actions: {
        onDoneEnter: () => findForDetail(props.id)
      }
    })
    return {
      state,
      send,
      form
    }
  }
})
