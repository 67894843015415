







































import { defineComponent, ref, watch } from '@vue/composition-api'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import DialogConfirm from '@/components/DialogConfirm.vue'
import repository from '@/domain/agendaTypeRepository'
import DialogInfo from '@/components/DialogInfo.vue'
import { useAgendaTemplates } from '@/composables/useAgendaTemplates'

export default defineComponent({
  components: {
    DialogInfo,
    DialogConfirm
  },
  props: ['id'],
  setup (props, { root }) {
    const { groupsForAgendaTypes, findForAgendaTypes } = useAgendaTemplates()
    findForAgendaTypes(props.id)

    const valid = ref(false)
    watch(groupsForAgendaTypes, () => {
      valid.value = true
    })

    const { state, send } = useMachine(promiseMachine, {
      context: {
        needsConfirmation: true
      },
      services: {
        invoke: (_, event: any) => repository.delete(event.value)
      },
      actions: {
        onDoneEnter: () => {
          root.$router.push({ name: 'AdminAgendaTypesManageList' })
        }
      }
    })

    return {
      state,
      send,
      groupsForAgendaTypes,
      valid
    }
  }
})
