var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mt-8"},[_c('router-link',{staticClass:"d-flex",attrs:{"to":{ name: 'AdminUsersManageList' }}},[_c('BaseIcon',{attrs:{"iconName":"mdiChevronLeft"}}),_c('span',[_vm._v("Back to Listing")])],1)],1),_c('h1',{staticClass:"text-h1 mt-8 mb-4"},[_vm._v("Manage User")]),_c('v-sheet',{staticClass:"px-4 py-4 mb-8"},[_c('h2',{staticClass:"text-h2 mb-3"},[_vm._v("User Information")]),_c('FormUserEdit',{attrs:{"form":_vm.form,"user":_vm.user,"state":_vm.state,"send":_vm.send}})],1),_c('div',{staticClass:"text-h2 mb-3"},[_vm._v("Ministry Permissions")]),_c('p',[_c('v-btn',{attrs:{"color":"primary"},nativeOn:{"click":function($event){return _vm.toggleForm()}}},[_vm._v("Add New")])],1),(_vm.showForm)?_c('v-sheet',{staticClass:"py-8 px-4 mb-4"},[_c('FormUserGroupMembershipCreate',{attrs:{"item":_vm.id,"id":"form"},on:{"close":function($event){_vm.showForm = false}}})],1):_vm._e(),(_vm.user)?_c('v-data-table',{staticClass:"ui-table mb-10",attrs:{"items":_vm.user.groupMembership,"headers":[
      { text: 'Ministry', value: 'group.formattedTitle' },
      { text: 'Title', value: 'title' },
      { text: 'Workflow Administrator', value: 'permissions.isAdmin', align: 'center', sortable: false },
      { text: 'Is High Ranking', value: 'permissions.isHighRanking', align: 'center', sortable: false },
      { text: 'All Documents', value: 'permissions.canAccessAllDocuments', align: 'center', sortable: false },
      { text: 'Actions', value: 'data-table-expand' } ],"hide-default-footer":"","expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.permissions.isAdmin",fn:function(ref){
    var item = ref.item;
return [_c('ChipBoolean',{attrs:{"status":item.permissions.isAdmin}})]}},{key:"item.permissions.isHighRanking",fn:function(ref){
    var item = ref.item;
return [_c('ChipBoolean',{attrs:{"status":item.permissions.isHighRanking}})]}},{key:"item.permissions.canAccessAllDocuments",fn:function(ref){
    var item = ref.item;
return [_c('ChipBoolean',{attrs:{"status":item.permissions.canAccessAllDocuments}})]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('FormUserGroupMembershipEdit',{attrs:{"item":item},on:{"close":_vm.removeFromExpanded}})],1)]}},{key:"item.data-table-expand",fn:function(ref){
    var expand = ref.expand;
    var select = ref.select;
    var isExpanded = ref.isExpanded;
    var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('BaseButton',{attrs:{"tile":false,"icon":""},nativeOn:{"click":function($event){return expand(!isExpanded)}}},[_c('BaseIcon',{attrs:{"iconName":isExpanded ? 'mdiCancel' : 'mdiLeadPencil',"size":"16px","title":"Edit"}})],1),_c('FormUserGroupMembershipDelete',{attrs:{"item":item}})],1)]}}],null,false,3021033399)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }