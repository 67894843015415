
















import { defineComponent, reactive } from '@vue/composition-api'
import { BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import FormGroupTypeEdit from '@/components/forms/FormGroupTypeEdit.vue'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import { UserGroupType } from '@/domain/userGroupType'
import repository from '@/domain/userGroupTypeRepository'

export default defineComponent({
  components: {
    BaseIcon,
    FormGroupTypeEdit
  },
  setup () {
    const form = reactive<UserGroupType>({
      title: ''
    })
    const { state, send } = useMachine(promiseMachine, {
      services: {
        invoke: () => repository.create(JSON.parse(JSON.stringify(form)))
      }
    })
    return {
      state,
      send,
      form
    }
  }
})
