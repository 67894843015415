var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ListingTitle',{attrs:{"type":"Ministry","subtext":"Manages both active and inactive entries for Ministries."}}),_c('v-sheet',{staticClass:"mb-5 pa-5",attrs:{"elevation":"1"}},[_c('v-row',[_c('v-col',[_c('ListingFormSearch',{attrs:{"state":_vm.state,"send":_vm.send}})],1)],1)],1),_c('ListingTableMeta',{attrs:{"state":_vm.state,"send":_vm.send,"route":{ name: 'AdminGroupsManageCreate' },"type":"Ministry"}}),_c('ListingTable',{attrs:{"state":_vm.state,"send":_vm.send,"handleRowClick":_vm.handleRowClick,"headers":[
    { text: 'Title', value: 'title', sortable: false },
    { text: 'Acronym', value: 'titleAbbreviation', sortable: false },
    { text: 'Type', value: 'type.title', sortable: false },
    { text: 'Active', value: 'isActive', sortable: false, align: 'center' }
  ]},scopedSlots:_vm._u([{key:"item.isActive",fn:function(ref){
  var item = ref.item;
return [_c('ChipBoolean',{attrs:{"status":item.isActive}})]}}])}),_c('ListingPagination',{attrs:{"state":_vm.state,"send":_vm.send}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }