



















import { defineComponent, reactive, watch } from '@vue/composition-api'
import { BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import FormWorkflowGroupEdit from '@/components/forms/FormWorkflowGroupEdit.vue'
import { WorkflowGroup } from '@/domain/workflowGroup'
import { useWorkflowGroups } from '@/composables/useWorkflowGroups'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import repository from '@/domain/workflowGroupRepository'

export default defineComponent({
  props: ['id'],
  components: {
    BaseIcon,
    FormWorkflowGroupEdit
  },
  setup (props) {
    const { findForDetail, detail } = useWorkflowGroups()
    findForDetail(props.id)
    const form = reactive<WorkflowGroup>({
      id: undefined,
      position: undefined,
      title: '',
      users: undefined,
      userGroup: undefined,
      userGroupType: undefined,
      workflowType: '',
      critical: false
    })
    watch(detail, () => {
      if (detail.value) {
        const params = JSON.parse(JSON.stringify(detail.value))
        Object.assign(form, params)
      }
    })
    const { state, send } = useMachine(promiseMachine, {
      services: {
        invoke: () => repository.update(form)
      },
      actions: {
        onDoneEnter: () => {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
          findForDetail(props.id)
        }
      }
    })
    return {
      state,
      send,
      form
    }
  }
})
