

























































































































import { defineComponent, ref } from '@vue/composition-api'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import ChipBlock from '@/components/ChipBlock.vue'
import ChipUser from '@/components/ChipUser.vue'
import { useGroupTypes } from '@/composables/useGroupTypes'
import FormWorkflowGroupDelete from '@/components/forms/FormWorkflowGroupDelete.vue'
import { notEmpty } from '@/validations'
import {
  BaseInputText,
  BaseInputSelect,
  BaseButton,
  BaseIcon
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useUser } from '@/composables/useUser'
import repositoryUserGroup from '@/domain/userGroupRepository'
import repositoryUser from '@/domain/userRepository'

export default defineComponent({
  props: ['form', 'state', 'send'],
  components: {
    BaseCombobox,
    ChipBlock,
    ChipUser,
    FormWorkflowGroupDelete,
    BaseInputText,
    BaseInputSelect,
    BaseButton,
    BaseIcon
  },
  setup (props) {
    const { selectText } = useUser()

    // User Group
    const userGroupSearchPromise = q => repositoryUserGroup.findByCriteria({ q })
    const userGroupItemText = s => s.title

    // User
    const userSearchPromise = c => repositoryUser.findByCriteria({ c })
    const userItemText = s => selectText(s)

    // User Group Types
    const { groupTypes, findAll } = useGroupTypes()
    findAll()

    const valid = ref(true)

    const updateUserGroupType = (event) => {
      props.form.userGroupType = event
      props.form.critical = false
    }

    const selectedUser = ref<any>(null)

    const appendUser = () => {
      if (selectedUser.value && typeof selectedUser.value !== 'string' && selectedUser.value.id) {
        if (props.form.users && props.form.users?.length) {
          props.form.users.push(selectedUser.value)
        } else {
          props.form.users = [selectedUser.value]
        }

        selectedUser.value = null
      }
    }

    const removeUser = (id) => {
      props.form.users = props.form.users.filter(x => x.id !== id)
    }

    return {
      userGroupSearchPromise,
      userGroupItemText,
      userSearchPromise,
      userItemText,
      groupTypes,
      valid,
      updateUserGroupType,
      selectedUser,
      appendUser,
      removeUser,
      notEmpty
    }
  }
})
