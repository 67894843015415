





















































import { defineComponent, ref } from '@vue/composition-api'
import { useGroupTypes } from '@/composables/useGroupTypes'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import { notEmpty } from '@/validations'
import { BaseInputText } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'

export default defineComponent({
  props: ['form', 'itemsOriginal', 'state', 'send'],
  components: {
    BaseCombobox,
    BaseInputText
  },
  setup () {
    const { groupTypes, findAll } = useGroupTypes()
    const valid = ref(true)
    findAll()
    return {
      valid,
      groupTypes,
      notEmpty
    }
  }
})
