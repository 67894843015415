











































import { computed, defineComponent, inject, ref } from '@vue/composition-api'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import { BaseInputTextarea } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useUser } from '@/composables/useUser'
import { notEmpty } from '@/validations'
import logRepository from '@/domain/logEventRepository'
import { authMachineSendKey } from '@/components/Auth.vue'
import { LogEventJsonldLogEventWriteLevelEnum } from '@zucommunications/gsk-docshare-web-api/api'

export default defineComponent({
  components: {
    BaseCombobox,
    BaseInputTextarea
  },
  setup (props, { root }) {
    // TODO: migrate to `const store = useStore()`
    const comment = ref('')
    const authMachineSend = inject(authMachineSendKey)
    const { selectText: itemText, search: searchPromise } = useUser()

    const selected = ref()
    const ssoUserImpersonation = window.localStorage.getItem('ssoUserImpersonation')
    if (ssoUserImpersonation) {
      selected.value = {
        firstName: window.localStorage.getItem('ssoUserImpersonation'),
        lastName: ''
      }
    }
    const itemValue = x => x
    const clicked = ref(false)
    const impersonate = async (upn) => {
      clicked.value = true
      await logRepository.create({
        subject: 'Impersonate',
        level: LogEventJsonldLogEventWriteLevelEnum.Informational,
        event: `${upn} was impersonated. User supplied comment is: "${comment.value}"`
      })
      if (authMachineSend) {
        authMachineSend({
          type: 'SSO_IMPERSONATION_ENABLE',
          data: {
            user: upn
          }
        })
      }
    }
    const commentPlaceholder = computed(() => {
      const name = (selected?.value) ? selected.value.firstName : 'this user'
      return `Why are you impersonating ${name}?`
    })
    return {
      selected,
      searchPromise,
      itemText,
      itemValue,
      impersonate,
      notEmpty,
      comment,
      commentPlaceholder,
      clicked
    }
  }
})
